@import url(https://fonts.googleapis.com/css?family=Work+Sans&display=swap);
.Panels_submit_text__1TgSX {
    color: #707070;
}

.Panels_start_button__3ff3d {
    margin-left: 22px;
    margin-top: 8px;
    padding-left: 20px;
    width: auto;
    border: solid 3px black; 
    border-radius: 10px;
}

.Panels_start_button__3ff3d :focus {
    outline: none;
    box-shadow: none;
}

.Panels_warning_text__hGTxy {
    color: rgb(218, 80, 67);
}

.Panels_panel__3JVKB, .Panels_standalone_button__3JgIa {
    border: 1px solid;
    border-radius: 5px;
    background: rgb(255, 255, 255);
    box-shadow: 0px 8px 15px rgba(181, 181, 181, 0.4);
    border: #e8e8e8;
}

.Panels_panel__3JVKB h3, .Panels_panel__3JVKB form, .Panels_table_wrapper__2be5i {
    padding: 0.5em 0.5em 0.5em 1em;
}
.Panels_panel_modal__Aeum1 form {
    padding-bottom: 0;
}
.Panels_panel_modal__Aeum1 thead tr {
    border-bottom: none;
}
.Panels_panel__3JVKB h3 {
    padding-top: 0em;
    padding-bottom: 0em;
}
.Panels_panel__3JVKB table {
    width: 100%;
}
.Panels_panel__3JVKB input[type=text], .Panels_panel__3JVKB input[type=password], .Panels_panel__3JVKB input[type=number], .Panels_panel__3JVKB input[type=date], .Panels_panel__3JVKB input[type=datetime-local], .Panels_panel__3JVKB select {
    width: 20em;
    box-sizing: border-box;
    border-radius: 0.2em;
    border: 1px solid;
    padding: 4px 10px;
}

.Panels_panel__3JVKB select {
    margin-left: 0;
}

.Panels_standalone_button__3JgIa, .Panels_panel__3JVKB button {
    background: none;
    font-weight: 800;
    font-size: 1em;
    padding-right: 0.8em;
    padding-left: 0.8em;
    border: none;
}

.Panels_td_button__dGOOO {
    margin-right: 2em;
}

.Panels_panel_row__MOAGe {
    display: flex;
    margin-bottom: 2em;
}
.Panels_panel_row__MOAGe .Panels_panel_double__39jkA:first-of-type {
    margin-right: 2.5%;
}

.Panels_panel_triple__bemko {
    margin-right: 1%;
}
.Panels_panel_triple__bemko:last-of-type {
    margin-right: 0;
}

.Panels_panel_single__2Dw0f {
    width: 100%;
}
.Panels_panel_double__39jkA {
    width: 50%;
}
.Panels_panel_triple__bemko {
    width: 33%;
}
.Panels_panel_triple_doublewide__1woAq {
    width: 66%;
}

.Panels_panel_single__2Dw0f td {
    padding-right: 3.5em;
}

.Panels_panel_modal__Aeum1, .Panels_panel_modal_video__ws4Qw {
    width: 100%;
    background: #fafafa;
}
.Panels_panel_modal__Aeum1 {
    padding: 14px 24px 24px;
}
.Panels_panel_modal__Aeum1 td {
    padding-left: 1em;
}
.Panels_panel_modal__Aeum1 button {
    margin-top: 1em;
}

.Panels_panel_modal_video__ws4Qw {
    padding: 24px;
}

.Panels_form_button__1s0_O {
    margin-top: 0.5em;
    margin: auto;
    display: inline-block;
    width: 8em;
}

.Panels_standalone_button__3JgIa {
    background: rgb(255, 255, 255);
    float: right;
    margin-left: 10px;
}

.Panels_standalone_button__3JgIa:hover {
    background: #fcfafa;
    text-decoration: underline;
}

.Panels_panel_radio__3PogV input[type=radio] {
    position: absolute;
    visibility: hidden;
    display: none;
}
  
.Panels_panel_radio__3PogV label {
    color: lighten(rgb(227, 232, 238), 40%);
    display: inline-block;
    cursor: pointer;
    font-weight: bold;
    padding: 5px 20px;
}
  
.Panels_panel_radio__3PogV input[type=radio]:checked + label{
    color: lighten(rgb(227, 232, 238), 60%);
    background: rgb(227, 232, 238);
}
  
.Panels_panel_radio__3PogV label + input[type=radio] + label {
    border-left: solid 3px rgb(227, 232, 238);
}

.Panels_panel_radio__3PogV .Panels_radio_group__2-UfY {
    border: solid 3px rgb(227, 232, 238);
    display: inline-block;
    margin-top: 5px;
    border-radius: 10px;
    overflow: hidden;
}

.Panels_status_wrapper__2C3PP {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
}

.Panels_status_wrapper_component__1fDz5{
    border: solid 3px rgb(227, 232, 238);
    border-radius: 15px;
    padding: 5px 15px;
    font-size: 10px;
}

.Panels_status_wrapper_component_label__1iI9Z{
    padding-top: 8px;
    padding-right: 15px;
    font-size: 14px;
    align-items: center;
}

.Panels_status_wrapper_component_round__1bTsL{
    max-height: 180px;
    margin-top: 5px;
    max-width: 160px;
    text-align: center;
}

.Panels_start_button__3ff3d {
    background: rgb(255, 255, 255);
    margin-left: 10px;
    border: solid 3px black;
}

.Panels_start_button__3ff3d:focus {
    outline: none;
    box-shadow: none;
}

.Panels_control_buttons__2yGGb{
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

@font-face {
    font-family: 'Avenir';
    src: local('Avenir'), url(/static/media/AvenirLTStd-Medium.4f995fa4.otf) format('truetype');
}

.LayoutCommon_header__2mMWJ {
    margin-bottom: 1em;
}
.LayoutCommon_header__2mMWJ p {
    font-size: 2.2em;
    margin: 0;
    padding-top: 0.9em;

    font-family: proxima-nova, sans-serif;
    font-family: Avenir, sans-serif;
    font-weight: 400;
    font-style: normal;
}

.LayoutCommon_footer__3No8m {
    margin: 2em auto auto;
}

.LayoutCommon_sidebar__2bb8o {
    width: 9%;
    margin-right: 1em;
    font-family: 'Noto Sans KR', sans-serif;
    font-weight: 200;
}

.LayoutCommon_sidebar_list__1Fjv_ {
    list-style-type: none;
    padding: 0;
    margin: 0
}

.LayoutCommon_sidebar_list__1Fjv_ li {
    padding: 0.2em 0;
}
.vjs-tech {
    transform: scale(-1, 1) !important;
}
.Spinner{
    height: 100px;
    width: 100px;
    /* display: flex; */
    /* align-items: center; */
    /* justify-content: center; */
    position: absolute;
    left: 50%;
    top: 50%;
    z-index: 1000;
    background: rgba(0,0,0,0.5);
    border-radius: 2px;
}
p {
    border-radius: 2px;
    border: 1px solid #eee;
    margin-bottom: 5px;
}
.Chat_chatbox__14HzS div, .Chat_chatbox__14HzS input {
    border: 1px solid;
    border-radius: 4px;
    width: 100%;
    padding: 5px;
    margin: 3px 0 10px 0;
}

.Chat_chatbox__14HzS div {
    border-color: grey;
    height: 300px;
    overflow: auto;
}

.Chat_chatbox__14HzS input {
    width: 100%;
    box-sizing: border-box;
    border-radius: 0.2em;
    border: 1px solid;
    padding: 4px 10px;
}

.Chat_answerSelected__1PG9L {
    color: blue
}

.Chat_chatbox__14HzS button {
    display: block
}

.Chat_chatbox__14HzS code {
    font-size: larger;
}
html, body {
    margin: 0;
    padding: 0;

    border-color: #1F1F1F;
    font-family: 'Work Sans', sans-serif;
}

html, body, a, hr {
    color: #1F1F1F;
}

h2 {
    padding: 0;
    margin: 0 0 0.5em;
}

a {
    text-decoration: none;
}
a:hover, button:hover {
    text-decoration: underline;
}

table {
    border-collapse: collapse;
}

thead tr {
    border-bottom: 1px solid #e0e0e0;
}

thead tr th {
    padding-bottom: 0.3em;
}

tbody tr:nth-child(even) {
    background-color: #f7f7f7;
}

tbody tr:hover {
    background-color: rgb(230, 231, 233);
}

th {
    text-align: left;
}
td, th {
    padding-right: 2em;
}
td:last-of-type, th:last-of-type {
    padding-right: 0;
}

td {
    padding-top: 0.35em;
    padding-bottom: 0.35em;
}

select {
    margin-left: 1em;
}
.message_panel {
    height: 200px;
    padding: 0.5em 0.5em 0.5em 1em;
    overflow: auto;
}

.message_input {
    text-align: center;
}

.message {
    padding-bottom: 7px;
}

.message p {
    margin: 0;
    padding: 0;
}
.message_text {
    font-size: larger;
}

.message_username {
    font-size: small;
}
