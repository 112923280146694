p {
    -moz-border-radius: 2px;
    border-radius: 2px;
    border: 1px solid #eee;
    margin-bottom: 5px;
}
.chatbox div, .chatbox input {
    border: 1px solid;
    -moz-border-radius: 4px;
    border-radius: 4px;
    width: 100%;
    padding: 5px;
    margin: 3px 0 10px 0;
}

.chatbox div {
    border-color: grey;
    height: 300px;
    overflow: auto;
}

.chatbox input {
    width: 100%;
    box-sizing: border-box;
    border-radius: 0.2em;
    border: 1px solid;
    padding: 4px 10px;
}

.answerSelected {
    color: blue
}

.chatbox button {
    display: block
}

.chatbox code {
    font-size: larger;
}