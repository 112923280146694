.message_panel {
    height: 200px;
    padding: 0.5em 0.5em 0.5em 1em;
    overflow: auto;
}

.message_input {
    text-align: center;
}

.message {
    padding-bottom: 7px;
}

.message p {
    margin: 0;
    padding: 0;
}
.message_text {
    font-size: larger;
}

.message_username {
    font-size: small;
}