.submit_text {
    color: #707070;
}

.start_button {
    margin-left: 22px;
    margin-top: 8px;
    padding-left: 20px;
    width: auto;
    border: solid 3px black; 
    border-radius: 10px;
}

.start_button :focus {
    outline: none;
    box-shadow: none;
}

.warning_text {
    color: rgb(218, 80, 67);
}

.panel, .standalone_button {
    border: 1px solid;
    border-radius: 5px;
    background: rgb(255, 255, 255);
    box-shadow: 0px 8px 15px rgba(181, 181, 181, 0.4);
    border: #e8e8e8;
}

.panel h3, .panel form, .table_wrapper {
    padding: 0.5em 0.5em 0.5em 1em;
}
.panel_modal form {
    padding-bottom: 0;
}
.panel_modal thead tr {
    border-bottom: none;
}
.panel h3 {
    padding-top: 0em;
    padding-bottom: 0em;
}
.panel table {
    width: 100%;
}
.panel input[type=text], .panel input[type=password], .panel input[type=number], .panel input[type=date], .panel input[type=datetime-local], .panel select {
    width: 20em;
    box-sizing: border-box;
    border-radius: 0.2em;
    border: 1px solid;
    padding: 4px 10px;
}

.panel select {
    margin-left: 0;
}

.standalone_button, .panel button {
    background: none;
    font-weight: 800;
    font-size: 1em;
    padding-right: 0.8em;
    padding-left: 0.8em;
    border: none;
}

.td_button {
    margin-right: 2em;
}

.panel_row {
    display: flex;
    margin-bottom: 2em;
}
.panel_row .panel_double:first-of-type {
    margin-right: 2.5%;
}

.panel_triple {
    margin-right: 1%;
}
.panel_triple:last-of-type {
    margin-right: 0;
}

.panel_single {
    width: 100%;
}
.panel_double {
    width: 50%;
}
.panel_triple {
    width: 33%;
}
.panel_triple_doublewide {
    width: 66%;
}

.panel_single td {
    padding-right: 3.5em;
}

.panel_modal, .panel_modal_video {
    width: 100%;
    background: #fafafa;
}
.panel_modal {
    padding: 14px 24px 24px;
}
.panel_modal td {
    padding-left: 1em;
}
.panel_modal button {
    margin-top: 1em;
}

.panel_modal_video {
    padding: 24px;
}

.form_button {
    margin-top: 0.5em;
    margin: auto;
    display: inline-block;
    width: 8em;
}

.standalone_button {
    background: rgb(255, 255, 255);
    float: right;
    margin-left: 10px;
}

.standalone_button:hover {
    background: #fcfafa;
    text-decoration: underline;
}

.panel_radio input[type=radio] {
    position: absolute;
    visibility: hidden;
    display: none;
}
  
.panel_radio label {
    color: lighten(rgb(227, 232, 238), 40%);
    display: inline-block;
    cursor: pointer;
    font-weight: bold;
    padding: 5px 20px;
}
  
.panel_radio input[type=radio]:checked + label{
    color: lighten(rgb(227, 232, 238), 60%);
    background: rgb(227, 232, 238);
}
  
.panel_radio label + input[type=radio] + label {
    border-left: solid 3px rgb(227, 232, 238);
}

.panel_radio .radio_group {
    border: solid 3px rgb(227, 232, 238);
    display: inline-block;
    margin-top: 5px;
    border-radius: 10px;
    overflow: hidden;
}

.status_wrapper {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
}

.status_wrapper_component{
    border: solid 3px rgb(227, 232, 238);
    border-radius: 15px;
    padding: 5px 15px;
    font-size: 10px;
}

.status_wrapper_component_label{
    padding-top: 8px;
    padding-right: 15px;
    font-size: 14px;
    align-items: center;
}

.status_wrapper_component_round{
    max-height: 180px;
    margin-top: 5px;
    max-width: 160px;
    text-align: center;
}

.start_button {
    background: rgb(255, 255, 255);
    margin-left: 10px;
    border: solid 3px black;
}

.start_button:focus {
    outline: none;
    box-shadow: none;
}

.control_buttons{
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}
