@import url('https://fonts.googleapis.com/css?family=Work+Sans&display=swap');

html, body {
    margin: 0;
    padding: 0;

    border-color: #1F1F1F;
    font-family: 'Work Sans', sans-serif;
}

html, body, a, hr {
    color: #1F1F1F;
}

h2 {
    padding: 0;
    margin: 0 0 0.5em;
}

a {
    text-decoration: none;
}
a:hover, button:hover {
    text-decoration: underline;
}

table {
    border-collapse: collapse;
}

thead tr {
    border-bottom: 1px solid #e0e0e0;
}

thead tr th {
    padding-bottom: 0.3em;
}

tbody tr:nth-child(even) {
    background-color: #f7f7f7;
}

tbody tr:hover {
    background-color: rgb(230, 231, 233);
}

th {
    text-align: left;
}
td, th {
    padding-right: 2em;
}
td:last-of-type, th:last-of-type {
    padding-right: 0;
}

td {
    padding-top: 0.35em;
    padding-bottom: 0.35em;
}

select {
    margin-left: 1em;
}