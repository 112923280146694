.Spinner{
    height: 100px;
    width: 100px;
    /* display: flex; */
    /* align-items: center; */
    /* justify-content: center; */
    position: absolute;
    left: 50%;
    top: 50%;
    z-index: 1000;
    background: rgba(0,0,0,0.5);
    border-radius: 2px;
}