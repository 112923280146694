@font-face {
    font-family: 'Avenir';
    src: local('Avenir'), url(./fonts/AvenirLTStd-Medium.otf) format('truetype');
}

.header {
    margin-bottom: 1em;
}
.header p {
    font-size: 2.2em;
    margin: 0;
    padding-top: 0.9em;

    font-family: proxima-nova, sans-serif;
    font-family: Avenir, sans-serif;
    font-weight: 400;
    font-style: normal;
}

.footer {
    margin: 2em auto auto;
}

.sidebar {
    width: 9%;
    margin-right: 1em;
    font-family: 'Noto Sans KR', sans-serif;
    font-weight: 200;
}

.sidebar_list {
    list-style-type: none;
    padding: 0;
    margin: 0
}

.sidebar_list li {
    padding: 0.2em 0;
}